import { ref } from '@vue/composition-api'
import store from '@/store'

export const useOaFiltersSortingAndPagination = () => {
  const filters = ref({
    q: '',
    page: 1,
    perPage: 4,
  })

  // Sorting
  const sortBy = ref({ text: 'Name', value: 'name' })
  const sortByOptions = [
    { text: 'Name', value: 'name' },
    { text: 'Channel ID', value: 'channel_id' },
  ]

  return {
    // Filter
    filters,

    // Sort
    sortBy,
    sortByOptions,
  }
}

export const useOaUi = () => {
  const itemView = 'grid-view'
  const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
  ]

  // Pagination count <= required by pagination component
  const totalLineOa = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalLineOa,
  }
}

export const useOaRemoteData = () => {
  const lineOaData = ref([])
  const getListLineOa = (...args) => store.dispatch('store-line-oa/getListLineOa', ...args)

  return {
    lineOaData,
    getListLineOa,
  }
}
