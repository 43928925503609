<template>
  <div style="height: inherit">
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- LINE-OA Header -->
      <section id="line-oa-header">
        <div class="row">
          <div class="col-sm-12">
            <div class="line-oa-header-items">
              <div class="result-toggler">
                <feather-icon
                  icon="MenuIcon"
                  class="d-block d-lg-none"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
                <div class="search-results">
                  {{ totalLineOa }} results found
                </div>
              </div>
              <div class="view-options d-flex">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="success"
                  type="button"
                  class="mr-1"
                  @click="onAddLineOa"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="14"
                  />
                  <span class="align-middle">
                    {{ `${$t('Add')} ${$t('LINE OA')}` }}
                  </span>
                </b-button>

                <!-- Sort Button -->
                <b-dropdown
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :text="sortBy.text"
                  right
                  variant="outline-primary"
                >
                  <b-dropdown-item
                    v-for="sortOption in sortByOptions"
                    :key="sortOption.value"
                    @click="sortBy=sortOption"
                  >
                    {{ sortOption.text }}
                  </b-dropdown-item>
                </b-dropdown>

                <!-- Item View Radio Button Group  -->
                <b-form-radio-group
                  v-model="itemView"
                  class="ml-1 list item-view-radio-group"
                  buttons
                  size="sm"
                  button-variant="outline-primary"
                >
                  <b-form-radio
                    v-for="option in itemViewOptions"
                    :key="option.value"
                    :value="option.value"
                  >
                    <feather-icon
                      :icon="option.icon"
                      size="18"
                    />
                  </b-form-radio>
                </b-form-radio-group>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Overlay -->
      <div class="body-content-overlay" />

      <!-- Searchbar -->
      <div class="line-oa-searchbar mt-1">
        <b-row>
          <b-col cols="12">
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="filters.q"
                :placeholder="$t('Search')"
                class="search-line-oa"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>

      <!-- List -->
      <section :class="itemView">
        <b-card
          v-for="(item, index) in lineOaData"
          :key="item.id"
          class="line-oa-card"
          no-body
        >
          <b-card-body>
            <b-card-text class="line-oa-form">
              <b-row>
                <b-col cols="12">
                  <b-form-checkbox
                    v-model="item.is_open"
                    class="custom-control-success"
                    name="check-button"
                    switch
                    style="float: right; margin-top: -0.4rem; padding: 0rem 0rem 0.2rem 0rem;"
                    @change="onIsOpen(item)"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                  <b-form-group
                    :label="`${$t('Name')} ${$t('LINE OA')}`"
                    label-for="line-oa-name"
                  >
                    <b-form-input
                      id="line-oa-name"
                      v-model="item.name"
                      class="form-info-box"
                      readonly
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    :label="`${$t('Create')} ${$t('Date')}`"
                    label-for="line-oa-create-date"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="CalendarIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="line-oa-create-date"
                        v-model="item.create_date_show"
                        class="form-info-box"
                        readonly
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    :label="`${$t('Create')} ${$t('Time')}`"
                    label-for="line-oa-create-time"
                  >
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="ClockIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="line-oa-create-time"
                        v-model="item.create_time_show"
                        class="form-info-box"
                        readonly
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <div style="float: right; margin-top: -0.4rem; padding: 0rem 0rem 0.2rem 0rem;">
                    <b-button
                      size="sm"
                      variant="outline-dark"
                      class="btn-icon rounded-circle cursor-pointer"
                      @click="onShowMore(index)"
                    >
                      <feather-icon
                        :icon="(item.show_more === false)? `ChevronDownIcon` : `ChevronUpIcon`"
                      />
                    </b-button>
                  </div>
                  <b-form-group
                    :label="$t('Channel ID')"
                    label-for="line-oa-channel-id"
                  >
                    <b-form-input
                      id="line-oa-channel-id"
                      v-model="item.channel_id"
                      class="form-info-box"
                      readonly
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="item.show_more === true">
                <b-col cols="12">
                  <b-form-group
                    :label="$t('Channel Secret')"
                    label-for="line-oa-channel-secret"
                  >
                    <b-form-input
                      id="line-oa-channel-secret"
                      v-model="item.channel_secret"
                      class="form-info-box"
                      readonly
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="item.show_more === true">
                <b-col cols="12">
                  <b-form-group
                    :label="$t('Channel Access Token')"
                    label-for="line-oa-channel-access-token"
                  >
                    <b-form-textarea
                      id="line-oa-channel-access-token"
                      v-model="item.channel_access_token"
                      class="form-info-box"
                      readonly
                      rows="4"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="item.show_more === true">
                <b-col cols="12">
                  <b-form-group
                    :label="`${$t('Key')} ${$t('LINE OA')}`"
                    label-for="line-oa-channel-access-token"
                  >
                    <b-input-group
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="line-oa-key"
                        v-model="item.key"
                        class="form-info-box"
                        readonly
                      />
                      <b-input-group-append
                        id="line-oa-key-copy"
                        is-text
                        @click="copyKey(item)"
                      >
                        <feather-icon
                          class="cursor-pointer"
                          icon="CopyIcon"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-popover
                    target="line-oa-key-copy"
                    placement="top"
                    triggers="hover"
                  >
                    <span>{{ $t('Copy') }}</span>
                  </b-popover>
                </b-col>
              </b-row>
              <b-row v-if="item.show_more === true">
                <b-col cols="12">
                  <b-form-group
                    :label="$t('Webhook URL')"
                    label-for="line-oa-channel-access-token"
                  >
                    <b-input-group
                      class="input-group-merge"
                    >
                      <b-form-input
                        id="line-oa-webhook-url"
                        v-model="item.webhook_url"
                        class="form-info-box"
                        readonly
                      />
                      <b-input-group-append
                        id="line-oa-webhook-url-copy"
                        is-text
                        @click="copyWebhookURL(item)"
                      >
                        <feather-icon
                          class="cursor-pointer"
                          icon="CopyIcon"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <b-popover
                    target="line-oa-webhook-url-copy"
                    placement="top"
                    triggers="hover"
                  >
                    <span>{{ $t('Copy') }}</span>
                  </b-popover>
                </b-col>
              </b-row>

            </b-card-text>
          </b-card-body>

          <!-- Actions -->
          <div class="item-options text-center">
            <b-button
              variant="light"
              class="btn-edit"
              @click="onEdit(item)"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              />
              <span>{{ $t('Edit') }}</span>
            </b-button>
            <b-button
              variant="danger"
              class="btn-delete"
              @click="onDelete(item)"
            >
              <feather-icon
                icon="TrashIcon"
                class="mr-50"
              />
              <span>{{ $t('Delete') }}</span>
            </b-button>
            <b-button
              variant="primary"
              class="btn-select"
              @click="onSelect(item)"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
              />
              <span>{{ $t('Select') }}</span>
            </b-button>
          </div>
        </b-card>
      </section>

      <!-- Pagination -->
      <section>
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="filters.page"
              :total-rows="totalLineOa"
              :per-page="filters.perPage"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>

      <!-- Sidebar -->
      <portal to="content-renderer-sidebar-detached-left">
        <oa-left-sidebar :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar" />
      </portal>

      <oa-form
        v-model="shallShowLineOaFormModal"
        :line-oa-data="lineOaFormData"
        :type-line-oa-form="typeLineOaForm"
        @line-oa-form-update="updateLineOaForm"
        @discard-line-oa-form="discardLineOaForm"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  // BLink,
  BCardText,
  BButton,
  BPagination,
  BOverlay,
  BFormGroup,
  BFormTextarea,
  BPopover,
  BFormCheckbox,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  watch,
  ref,
  onUnmounted,
} from '@vue/composition-api'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import OaLeftSidebar from './OaLeftSidebar.vue'
import { useOaFiltersSortingAndPagination, useOaUi, useOaRemoteData } from './useOa'
import storeLineOa from './storeLineOa'
import OaForm from './OaForm.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    // BLink,
    BCardText,
    BButton,
    BPagination,
    BOverlay,
    BFormGroup,
    BFormTextarea,
    BPopover,
    BFormCheckbox,
    BInputGroupPrepend,

    OaLeftSidebar,
    OaForm,
    FeatherIcon,
  },
  data() {
    return {
      showOverlay: false,
      lineOaDefault: {},
      lineOaFormData: {},
    }
  },
  computed: {
    prevRoute() {
      const value = this.$router.currentRoute.params.prevRoute
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  methods: {
    getDefaultLineOa() {
      this.showOverlay = true
      store
        .dispatch('store-line-oa/getDefaultLineOa', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.lineOaDefault = response.data
          this.fetchLineOa()
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    onAddLineOa() {
      this.showOverlay = true
      store
        .dispatch('store-line-oa/getDefaultLineOa', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.lineOaDefault = response.data
          this.lineOaFormData = this.lineOaDefault
          this.typeLineOaForm = 'Add'
          this.shallShowLineOaFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    updateLineOaForm() {
      this.fetchLineOa()
    },
    discardLineOaForm() {
      // this.fetchLineOa()
    },
    copyKey(item) {
      const copyText = item.key
      navigator.clipboard.writeText(copyText).then(() => {
        const okMsg = this.$i18n.t('Copied to clipboard')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: okMsg,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      })
    },
    copyWebhookURL(item) {
      const copyText = item.webhook_url
      navigator.clipboard.writeText(copyText).then(() => {
        const okMsg = this.$i18n.t('Copied to clipboard')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: okMsg,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      })
    },
    onEdit(item) {
      const { id } = item
      this.showOverlay = true
      store
        .dispatch('store-line-oa/getLineOa', { language: this.$i18n.locale, id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.lineOaFormData = response.data
          this.typeLineOaForm = 'Edit'
          this.shallShowLineOaFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    onDelete(item) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          this.showOverlay = true
          store.dispatch('store-line-oa/deleteLineOa', { id: item.id })
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.showOverlay = false
              this.fetchLineOa()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
              this.showOverlay = false
            })
        }
      })
    },
    onSelect(item) {
      const lineOa = JSON.stringify(item)
      store.commit('lineapi/UPDATE_LINE_OA', lineOa)
      if (this.prevRoute !== '') {
        this.$router.push({ name: this.prevRoute })
      } else {
        this.$router.push({ name: 'home' })
      }
    },
    onShowMore(index) {
      this.lineOaData[index].show_more = !this.lineOaData[index].show_more
    },
    onIsOpen(item) {
      let status = this.$i18n.t('Open')
      if (item.is_open === true) {
        status = this.$i18n.t('Open')
      } else {
        status = this.$i18n.t('Close')
      }
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: `${this.$i18n.t('Do you want change status to')} » "${status}"`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, change it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          this.showOverlay = true
          store
            .dispatch('store-line-oa/changeStatusLineOa', { id: item.id, is_open: item.is_open })
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.showOverlay = false
              this.fetchLineOa()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                const errorMsg = this.$i18n.t(response?.data.error)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
              this.showOverlay = false
            })
        } else {
          item.is_open = !item.is_open
        }
      })
    },
  },
  setup() {
    const shallShowLineOaFormModal = ref(false)
    const typeLineOaForm = ref('')
    const userData = JSON.parse(localStorage.getItem('adx-user-data'))

    if (!store.hasModule('store-line-oa')) store.registerModule('store-line-oa', storeLineOa)
    onUnmounted(() => {
      if (store.hasModule('store-line-oa')) store.unregisterModule('store-line-oa')
    })

    const {
      filters, sortBy, sortByOptions,
    } = useOaFiltersSortingAndPagination()

    const {
      itemView, itemViewOptions, totalLineOa,
    } = useOaUi()

    const { lineOaData, getListLineOa } = useOaRemoteData()

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const fetchLineOa = () => {
      getListLineOa({
        q: filters.value.q,
        sortBy: sortBy.value.value,
        page: filters.value.page,
        perPage: filters.value.perPage,
        userId: userData.id,
      })
        .then(response => {
          const { list, total } = response.data
          lineOaData.value = list
          totalLineOa.value = total
        })
    }

    fetchLineOa()

    watch([filters, sortBy], () => {
      fetchLineOa()
    }, {
      deep: true,
    })

    return {
      shallShowLineOaFormModal,
      typeLineOaForm,
      userData,

      // useOaFiltersSortingAndPagination
      filters,
      sortBy,
      sortByOptions,

      // useOaUi
      itemView,
      itemViewOptions,
      totalLineOa,

      // useOaRemoteData
      lineOaData,
      fetchLineOa,

      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/line/line-oa.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.line-oa-form {
  .input-group {
    .input-group-text {
      background-color: #f8f8f8;
      border: 1px solid #f0f0f0;
    }
    :focus, :focus-within {
      border: 1px solid #f0f0f0;
    }
  }
}
.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>
